<!-- 首页 -->
<template>
  <div class="container">
    <!-- 头部 -->
    <IndexHeader></IndexHeader>
    <!-- 背景图 -->
    <div class="bannerImg" id="nav">
      <div class="center">
        <h1 class="slogan" style="margin-bottom: 0">在线统计分析云平台</h1>
        <h4 class="slogan_en" style="margin-top: 0">
          基于R语言的WEB可视化统计分析工具
        </h4>
        <p class="desc">
          集成R统计包、云端高性能分析、可视化结果呈现、支持多人协作分析
        </p>
        <p class="desc">
          R Integration, Cloud Analysis, Visualized Results, Collaboration
          Support.
        </p>
      </div>
    </div>

    <!-- icon区域 -->
    <div class="icon-nav">
      <div class="icon-banner">
        <el-row>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="grid-content icon-box">
              <div class="iconBox" @click="goSpider('#spider1')">
                <img src="../../assets/imgs/index/spider-template.png" alt="" />
              </div>
              <span>集成R统计包</span>
              <p>集成常用统计分析算法，轻松学会统计分析工具。</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="grid-content icon-box">
              <div class="iconBox" @click="goSpider('#spider2')">
                <img src="../../assets/imgs/index/data-collect.png" alt="" />
              </div>
              <span>云端高性能分析</span>
              <p>支持云端存储数据集，支持云端保存分析条件和结果。</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="grid-content icon-box">
              <div class="iconBox" @click="goSpider('#spider3')">
                <img src="../../assets/imgs/index/search-engine.png" alt="" />
              </div>
              <span>可视化结果呈现</span>
              <p>通过条件筛选数据集，呈现分析数据图表以及分析报告等。</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="grid-content icon-box">
              <div class="iconBox" @click="goSpider('#spider4')">
                <img src="../../assets/imgs/index/text-analysis.png" alt="" />
              </div>
              <span>支持多人协作分析</span>
              <p>可以共享分析数据，分析结果也可以多人共享。</p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 主体内容区域 -->
    <div class="y-main" v-if="isMain">
      <div class="icon-banner">
        <el-row id="spider1" class="nav-box">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/index/1.png"
                  alt=""
                  style="border: 1px solid #e2e2e2"
                />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <div style="width: 405px; margin: 0 auto">
                <h4 class="content-title">集成R统计包</h4>
                <ul class="content-item">
                  <li>基于业界主流的R统计分析包</li>
                  <li>集成常用统计分析算法</li>
                  <li>支持扩展高级统计分析算法</li>
                  <li>支持统计分析教程，轻松学会统计分析工具</li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row id="spider2" class="nav-box">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <div style="width: 405px; margin: 0 auto">
                <h4 class="content-title">云端高性能分析</h4>
                <ul class="content-item">
                  <li>高性能分布式计算集群</li>
                  <li>支持云端存储数据集</li>
                  <li>支持云端保存分析条件和结果</li>
                  <li>支持主流浏览器</li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img
                  src="../../assets/imgs/index/2.png"
                  alt=""
                  style="border: 1px solid #e2e2e2"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="bgc">
        <div class="icon-banner">
          <el-row class="bgc nav-box" id="spider3">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img
                    src="../../assets/imgs/index/3.png"
                    alt=""
                    style="border: 1px solid #e2e2e2"
                  />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <div style="width: 405px; margin: 0 auto">
                  <h4 class="content-title">可视化结果呈现</h4>
                  <ul class="content-item">
                    <li>支持分析条件的可视化操作</li>
                    <li>支持通过条件筛选数据集</li>
                    <li>支持可视化分析结果图表</li>
                    <li>支持智能分析报告</li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="bgc nav-box" id="spider4">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <div style="width: 405px; margin: 0 auto">
                  <h4 class="content-title">支持多人协作分析</h4>
                  <ul class="content-item">
                    <li>支持共享分析数据</li>
                    <li>支持对分析数据的权限管理</li>
                    <li>分析条件和结果可以在多人之间共享</li>
                    <li>支持以链接的方式发布分析结果</li>
                  </ul>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img
                    src="../../assets/imgs/index/4.png"
                    alt=""
                    style="border: 1px solid #e2e2e2"
                  />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="y-main" v-else>
      <div class="icon-banner">
        <el-row id="spider11" class="nav-box">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img src="../../assets/imgs/index/1.png" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">集成R统计包</h4>
              <ul class="content-item">
                <li>基于业界主流的R统计分析包</li>
                <li>集成常用统计分析算法</li>
                <li>支持扩展高级统计分析算法</li>
                <li>支持统计分析教程，轻松学会统计分析工具</li>
              </ul>
            </div>
          </el-col>
        </el-row>
        <el-row id="spider21" class="nav-box">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img src="../../assets/imgs/index/2.png" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">云端高性能分析</h4>
              <ul class="content-item">
                <li>高性能分布式计算集群</li>
                <li>支持云端存储数据集</li>
                <li>支持云端保存分析条件和结果</li>
                <li>支持主流浏览器</li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row class="bgc nav-box" id="spider31">
        <div class="icon-banner">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img src="../../assets/imgs/index/3.png" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">可视化结果呈现</h4>
              <ul class="content-item">
                <li>支持分析条件的可视化操作</li>
                <li>支持通过条件筛选数据集</li>
                <li>支持可视化分析结果图表</li>
                <li>支持智能分析报告</li>
              </ul>
            </div>
          </el-col>
        </div>
      </el-row>
      <el-row class="bgc nav-box" id="spider41">
        <div class="icon-banner">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img src="../../assets/imgs/index/4.png" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <div style="width: 405px; margin: 0 auto">
                <h4 class="content-title">支持多人协作分析</h4>
                <ul class="content-item">
                  <li>支持共享分析数据</li>
                  <li>支持对分析数据的权限管理</li>
                  <li>分析条件和结果可以在多人之间共享</li>
                  <li>支持以链接的方式发布分析结果</li>
                </ul>
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>

    <!-- 客户类型 -->
    <div class="clinet">
      <div class="header">
        <h1 class="title">客户类型</h1>
      </div>

      <div class="client-nav icon-banner">
        <el-row>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/index/client-enterprise.png"
                />
              </div>
              <h4 class="client-type">企业客户</h4>
              <ul class="client-list">
                <li>企业舆情分析</li>
                <li>竞争情报分析</li>
                <li>招投标监测</li>
                <li>价格指数监测</li>
                <li>产品评价分析</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/index/client-investigate.png"
                />
              </div>
              <h4 class="client-type">市场调研机构</h4>
              <ul class="client-list">
                <li>市场调查与分析</li>
                <li>产品研究</li>
                <li>用户行为研究</li>
                <li>行业研究</li>
                <li>决策咨询</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/index/client-education.png"
                />
              </div>
              <h4 class="client-type">科研教育机构</h4>
              <ul class="client-list padding-left70">
                <li>大数据课程培训</li>
                <li>科研分析</li>
                <li>新闻舆情分析</li>
                <li>网络心态研究</li>
                <li>社会热点研究</li>
              </ul>
            </div>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
            <div class="client-grid">
              <div class="icon-card">
                <img
                  class="img-icon"
                  src="../../assets/imgs/index/client-government.png"
                />
              </div>
              <h4 class="client-type">政府机关</h4>
              <ul class="client-list">
                <li>社情民意调查</li>
                <li>政府舆情分析</li>
                <li>企业舆情分析</li>
                <li>海外舆情分析</li>
                <li>新闻大数据平台</li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 使用锐研 -->
    <div class="icon-banner use">
      <div class="use-header">
        <h1 class="title">他们正在使用云统计</h1>
      </div>
      <div class="use-body">
        <div class="logo-grids" v-if="isLogo">
          <div class="logo">
            <el-row>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo1.png"
                      title="南京大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo2.png"
                      title="复旦大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo3.png"
                      title="四川大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo4.png"
                      title="华南理工大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo5.png"
                      title="同济大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo6.png"
                      title="华东师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo7.png"
                      title="四川外国语大学"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="logo-bottom">
            <el-row>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo8.png"
                      title="北京师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo11.png"
                      title="江苏省电台"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo12.png"
                      title="上海市公安局"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo13.png"
                      title="腾讯网"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo9.png"
                      title="河海大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo10.png"
                      title="紫金传媒智库"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="logo-grids" v-else>
          <div class="logo">
            <el-row>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo1.png"
                      title="南京大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo2.png"
                      title="复旦大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo3.png"
                      title="四川大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo4.png"
                      title="华南理工大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo5.png"
                      title="同济大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo6.png"
                      title="华东师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo7.png"
                      title="四川外国语大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo8.png"
                      title="北京师范大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo11.png"
                      title="江苏省电台"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo12.png"
                      title="上海市公安局"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo13.png"
                      title="腾讯网"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo9.png"
                      title="河海大学"
                    />
                  </div>
                </div>
              </el-col>
              <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                <div class="logo-grid">
                  <div class="logo-card">
                    <img
                      src="../../assets/imgs/index/logo10.png"
                      title="紫金传媒智库"
                    />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="section-bottom">
        <router-link to="/case">
          <a href="#">案例</a>
          <i class="el-icon-arrow-right"></i>
        </router-link>
      </div>
    </div>

    <!-- 底部 -->
    <BaseFooter></BaseFooter>
  </div>
</template>

<script>
import IndexHeader from "@/components/layout/IndexHeader.vue";
import BaseFooter from "@/components/layout/BaseFooter.vue";
export default {
  components: {
    IndexHeader,
    BaseFooter,
  },
  data() {
    return {
      isActive: 1, // 切换导航
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
      isMain: true,
      screenWidth: "",
      isLogo: true,
      flag: false,
    };
  },

  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth <= 768) {
      this.isMain = false;
      this.isLogo = false;
    } else {
      this.isMain = true;
      this.isLogo = true;
    }
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth <= 768) {
          this.isMain = false;
          this.isLogo = false;
        } else {
          this.isMain = true;
          this.isLogo = true;
        }
      })();
    };
  },

  methods: {
    // 切换导航
    changeActive(current) {
      if (current != 1) {
        this.destroyed();
      }
      this.isActive = current;
    },

    // 锚点跳转
    goSpider(id) {
      if (this.isMain == true) {
        this.$el.querySelector(id).scrollIntoView(true);
      } else {
        this.$el.querySelector(id + "1").scrollIntoView(true);
      }
    },

    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    // 销毁滚轮事件
    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.flag = !this.flag;
      this.menuBox = this.flag;
    },
  },
};
</script>
<style lang="scss" scoped>
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #444;
}
button,
button:focus {
  outline: none;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
<style scoped>
.header-right >>> .el-button {
  padding: 8px 13px;
  margin-left: 30px;
  margin-bottom: 3px;
}
</style>
<style lang="scss" scoped>
@import "../../assets/styles/media.scss";
* {
  font-weight: 300;
}
.mr {
  margin-right: 0 !important;
}
#user {
  line-height: 0;
}
.container {
  width: 100%;
  box-sizing: border-box;
}

.menu {
  font-size: 28px;
  color: #666;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 60px;
}
.menuBox {
  z-index: 1000;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  li {
    cursor: pointer;
    z-index: 1000;
    a {
      &:hover {
        color: #389bca;
      }
      &.active {
        color: #389bca;
      }
    }
  }
  .menu-setting {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .register {
      width: 70px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-right: 40px;
    }
  }
}
.into {
  margin-left: 30px;
  margin-right: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 13px;
  i {
    font-weight: 500;
    margin-left: 3px;
    vertical-align: baseline;
  }
}
.icon-banner {
  max-width: 1200px;
  margin: 0 auto;
}

// 背景图
.bannerImg {
  width: 100%;
  color: #fff;
  text-align: center;
  background: url("../../assets/imgs/index/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // background-position: center center;
  // min-height: 790px;
  .center {
    padding: 200px 0;
    max-width: 1200px;
    margin: 0 auto;
    .slogan {
      text-align: left;
      font-size: 40px;
      font-weight: 300;
      color: #fff;
      margin-top: 60px;
      padding: 30px 15px 10px;
    }
    .slogan_en {
      text-align: left;
      color: #fff;
      font-size: 25px;
      font-weight: 300;
      padding: 0 15px 20px;
    }
    .desc {
      font-size: 20px;
      font-weight: 300;
      text-align: left;
      color: #fff;
      margin: 0 auto;
      margin-bottom: 5px;
      padding: 0 15px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    }
  }
}

// icon区域
.icon-nav {
  padding: 80px 20px;
  background-color: #f7f8fd;
  .icon-box {
    text-align: center;
    padding: 10px 15px;
    .iconBox {
      margin: 0 auto 0;
      width: 120px;
      height: 120px;
      line-height: 115px;
      background-color: #ccc;
      border-radius: 50%;
      &:hover {
        background-color: #389bca;
      }
      img {
        width: 80px;
        height: 80px;
        margin-top: 20px;
      }
    }
    span {
      display: inline-block;
      margin: 25px 0;
      font-size: 18px;
      font-weight: 300;
    }
    p {
      color: #666;
      font-weight: 300;
    }
  }
}

// 主体内容区域
.y-main {
  .bgc {
    background-color: #f7f8fd;
  }
  .main-img {
    padding: 20px 40px;
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .main-text {
    padding: 10px 40px;
    .content-title {
      font-size: 25px;
      color: #495766;
      font-weight: 300;
      margin: 20px 0px 30px;
    }
    .content-item {
      color: #666;
      padding-left: 20px;
      li {
        list-style: disc !important;
        margin: 10px 0px;
      }
    }
  }
}

// 客户类型
.clinet {
  padding: 0px 0px 50px;
  .header {
    .title {
      text-align: center;
      font-size: 28px;
      font-weight: 300;
      color: #495766;
      margin: 20px 0px 30px;
    }
  }
  .client-nav {
    overflow: hidden;
  }
  .client-grid {
    padding-left: 20px;
    width: 100%;
    float: left;
    .icon-card {
      width: 80px;
      margin: 0 auto;
      .img-icon {
        width: 70px;
        height: 70px;
      }
    }
    .client-type {
      font-size: 18px;
      font-weight: 300;
      color: #444;
      text-align: center;
      padding: 15px 0px 0px;
    }
    .client-list {
      color: #666;
      padding-left: 20px;
      li {
        list-style: disc !important;
        margin: 10px 0px;
        color: #666;
        font-weight: 300;
      }
    }
  }
}

// 使用锐研
.use {
  padding: 0 10px;
  .use-header {
    .title {
      text-align: center;
      font-size: 30px;
      color: #666;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }
  .use-body {
    overflow: hidden;
    .logo-grids {
      margin: 0 auto;
      height: auto;
      width: 100%;
      text-align: center;
      .logo-card {
        width: 100%;
      }
    }
    img {
      width: 100%;
    }
  }

  .logo-bottom {
    img {
      width: 100%;
    }
  }
  .section-bottom {
    overflow: hidden;
    margin: 40px 0;
    text-align: center;
    a {
      font-size: 16px;
      color: #389bca;
      font-weight: 500;
    }
    i {
      font-weight: bold;
    }
  }
}

// 底部
.footer {
  padding: 55px 10px;
  text-align: center;
  background: #eee;
  .social-icons img {
    display: inline-block;
    width: 25px;
    height: 25px;
  }
  .social-icons a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    background: #bdbdbd;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -webkit-filter: alpha(opacity=80);
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
    }
    img {
      margin-top: 10px;
    }
  }
  .footer-middle {
    color: #a0a0a0;
    padding: 10px 0px;
    font-size: 12px;
  }
  .footer-bottom {
    font-size: 12px;
    p {
      color: #a0a0a0;
    }
    a {
      color: #389bca;
    }
  }
}
</style>
